export default {
    'setting':`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 13">
    <path d="M2.34616 12.6978L6.03285 8.32174C6.23598 8.41739 6.44164 8.48913 6.64984 8.53696C6.85804 8.58478 7.07894 8.6087 7.31253 8.6087C8.32815 8.6087 9.19142 8.19022 9.90235 7.35326C10.6133 6.5163 10.9688 5.5 10.9688 4.30435C10.9688 3.87391 10.918 3.4644 10.8164 3.07581C10.7148 2.68723 10.5727 2.31957 10.3898 1.97283L8.16565 4.5913L7.06878 3.3L9.29298 0.681522C8.99845 0.466304 8.68615 0.298913 8.35608 0.179348C8.026 0.0597826 7.67815 0 7.31253 0C6.29691 0 5.43364 0.418478 4.72271 1.25543C4.01178 2.09239 3.65631 3.1087 3.65631 4.30435C3.65631 4.57935 3.67662 4.8394 3.71725 5.08451C3.75787 5.32962 3.81881 5.57174 3.90006 5.81087L0.182899 10.1511C0.0610256 10.2946 8.86917e-05 10.4679 8.86917e-05 10.6712C8.86917e-05 10.8745 0.0610256 11.0478 0.182899 11.1913L1.46258 12.6978C1.58445 12.8413 1.73172 12.913 1.90437 12.913C2.07703 12.913 2.22429 12.8413 2.34616 12.6978ZM1.90437 11.1734L1.49305 10.6891L5.39301 6.09783C5.2102 5.8587 5.07817 5.58071 4.99692 5.26386C4.91567 4.94701 4.87505 4.62717 4.87505 4.30435C4.87505 3.58696 5.07056 2.96223 5.46157 2.43016C5.85258 1.8981 6.33754 1.57826 6.91644 1.47065L5.7891 2.79783C5.66723 2.9413 5.60629 3.1087 5.60629 3.3C5.60629 3.4913 5.66723 3.6587 5.7891 3.80217L7.73909 6.09783C7.86096 6.2413 8.00315 6.31304 8.16565 6.31304C8.32815 6.31304 8.47033 6.2413 8.59221 6.09783L9.71954 4.77065C9.62814 5.45217 9.35646 6.0231 8.90451 6.48342C8.45256 6.94375 7.9219 7.17391 7.31253 7.17391C7.04847 7.17391 6.78441 7.12609 6.52035 7.03043C6.25629 6.93478 6.01762 6.78533 5.80434 6.58207L1.90437 11.1734Z"/>
    </svg>`,
    
    'filter':`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12">
    <path d="M7.16667 11.5V9.66667H10.8333V11.5H7.16667ZM3.5 6.91667V5.08333H14.5V6.91667H3.5ZM0.75 2.33333V0.5H17.25V2.33333H0.75Z"/>
    </svg>`,

    'close-panel':`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 17">
    <path d="M11.75 12.3204V5.15988L8.41667 8.74013L11.75 12.3204ZM2.16667 16.7957C1.70833 16.7957 1.31597 16.6204 0.989583 16.2698C0.663194 15.9193 0.5 15.4978 0.5 15.0056V2.47469C0.5 1.98241 0.663194 1.56098 0.989583 1.21042C1.31597 0.859853 1.70833 0.68457 2.16667 0.68457H13.8333C14.2917 0.68457 14.684 0.859853 15.0104 1.21042C15.3368 1.56098 15.5 1.98241 15.5 2.47469V15.0056C15.5 15.4978 15.3368 15.9193 15.0104 16.2698C14.684 16.6204 14.2917 16.7957 13.8333 16.7957H2.16667ZM4.66667 15.0056V2.47469H2.16667V15.0056H4.66667ZM6.33333 15.0056H13.8333V2.47469H6.33333V15.0056Z"/>
    </svg>`,
    'open-panel':`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
    <path d="M9.5 5V13L13.5 9L9.5 5ZM2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H16C16.55 0 17.0208 0.195833 17.4125 0.5875C17.8042 0.979167 18 1.45 18 2V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM5 16V2H2V16H5ZM7 16H16V2H7V16Z"/>
    </svg>`,
    
    'vector':`<svg viewBox="0 0 16 2"  xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 1H14.5" stroke="#6B7280" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`, 
    'arrow-left':`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="16" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="16" xml:space="preserve"><polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "/></svg>`,
    'arrow-right':`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="16" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" width="16" xml:space="preserve"><polygon points="160,128.4 192.3,96 352,256 352,256 352,256 192.3,416 160,383.6 287.3,256 "/></svg>`,
    'search': `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.71,20.29,18,16.61A9,9,0,1,0,16.61,18l3.68,3.68a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM11,18a7,7,0,1,1,7-7A7,7,0,0,1,11,18Z"/></svg>`,
    'logout': `<svg xmlns="http://www.w3.org/2000/svg" class="svg_icn" width="20" height="20" viewBox="0 0 18 23">
    <path d="M14 6.84821L12.59 8.5087L14.17 10.3812H6V12.7365H14.17L12.59 14.5972L14 16.2694L18 11.5588L14 6.84821ZM2 3.31526H9V0.959961H2C0.9 0.959961 0 2.01985 0 3.31526V19.8024C0 21.0978 0.9 22.1577 2 22.1577H9V19.8024H2V3.31526Z"/>
    </svg>`,

    'dashboard': `<svg class="svg_icn" width="24px" height="24px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.288"></g><g id="SVGRepo_iconCarrier"> <path d="M2 12H9.5M22 12H14.5" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> <path d="M20.0002 15.6843C20.0002 19 17.7345 22 16.0002 22C13.7315 22 12.0723 18.8421 12.0723 12C12.0723 5.15794 10.4128 1.99988 8.14404 1.99988C6.40978 1.99988 4.14404 4.99988 4.14404 8.31567" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"></path> </g></svg>`,
    'produit': `<svg xmlns="http://www.w3.org/2000/svg" class="svg_icn" width="24" height="24"viewBox="0 0 24 24" fill="none">
    <path d="M10 14H12M12 14H14M12 14V16M12 14V12" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M2 6.94975C2 6.06722 2 5.62595 2.06935 5.25839C2.37464 3.64031 3.64031 2.37464 5.25839 2.06935C5.62595 2 6.06722 2 6.94975 2C7.33642 2 7.52976 2 7.71557 2.01738C8.51665 2.09229 9.27652 2.40704 9.89594 2.92051C10.0396 3.03961 10.1763 3.17633 10.4497 3.44975L11 4C11.8158 4.81578 12.2237 5.22367 12.7121 5.49543C12.9804 5.64471 13.2651 5.7626 13.5604 5.84678C14.0979 6 14.6747 6 15.8284 6H16.2021C18.8345 6 20.1506 6 21.0062 6.76946C21.0849 6.84024 21.1598 6.91514 21.2305 6.99383C22 7.84935 22 9.16554 22 11.7979V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V6.94975Z" stroke="#1C274C" stroke-width="1.5"/>
    </svg>`,

    'demandes': `<svg xmlns="http://www.w3.org/2000/svg" class="svg_icn" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M17.4142 10.4142C18 9.82843 18 8.88562 18 7C18 5.11438 18 4.17157 17.4142 3.58579M17.4142 10.4142C16.8284 11 15.8856 11 14 11H10C8.11438 11 7.17157 11 6.58579 10.4142M17.4142 10.4142C17.4142 10.4142 17.4142 10.4142 17.4142 10.4142ZM17.4142 3.58579C16.8284 3 15.8856 3 14 3L10 3C8.11438 3 7.17157 3 6.58579 3.58579M17.4142 3.58579C17.4142 3.58579 17.4142 3.58579 17.4142 3.58579ZM6.58579 3.58579C6 4.17157 6 5.11438 6 7C6 8.88562 6 9.82843 6.58579 10.4142M6.58579 3.58579C6.58579 3.58579 6.58579 3.58579 6.58579 3.58579ZM6.58579 10.4142C6.58579 10.4142 6.58579 10.4142 6.58579 10.4142Z" stroke="#1C274C" stroke-width="1.5"/>
    <path d="M13 7C13 7.55228 12.5523 8 12 8C11.4477 8 11 7.55228 11 7C11 6.44772 11.4477 6 12 6C12.5523 6 13 6.44772 13 7Z" stroke="#1C274C" stroke-width="1.5"/>
    <path d="M18 6C16.3431 6 15 4.65685 15 3" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M18 8C16.3431 8 15 9.34315 15 11" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M6 6C7.65685 6 9 4.65685 9 3" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M6 8C7.65685 8 9 9.34315 9 11" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M5 20.3884H7.25993C8.27079 20.3884 9.29253 20.4937 10.2763 20.6964C12.0166 21.0549 13.8488 21.0983 15.6069 20.8138C16.4738 20.6734 17.326 20.4589 18.0975 20.0865C18.7939 19.7504 19.6469 19.2766 20.2199 18.7459C20.7921 18.216 21.388 17.3487 21.8109 16.6707C22.1736 16.0894 21.9982 15.3762 21.4245 14.943C20.7873 14.4619 19.8417 14.462 19.2046 14.9433L17.3974 16.3084C16.697 16.8375 15.932 17.3245 15.0206 17.4699C14.911 17.4874 14.7962 17.5033 14.6764 17.5172M14.6764 17.5172C14.6403 17.5214 14.6038 17.5254 14.5668 17.5292M14.6764 17.5172C14.8222 17.486 14.9669 17.396 15.1028 17.2775C15.746 16.7161 15.7866 15.77 15.2285 15.1431C15.0991 14.9977 14.9475 14.8764 14.7791 14.7759C11.9817 13.1074 7.62942 14.3782 5 16.2429M14.6764 17.5172C14.6399 17.525 14.6033 17.5292 14.5668 17.5292M14.5668 17.5292C14.0434 17.5829 13.4312 17.5968 12.7518 17.5326" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    <rect x="2" y="14" width="3" height="8" rx="1.5" stroke="#1C274C" stroke-width="1.5"/>
    </svg>`,

    'shopping-cart': `<svg class="svg_icn" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
    <path d="M12.2891 41.8327C11.1661 41.8327 10.2049 41.4247 9.40521 40.6087C8.60556 39.7928 8.20573 38.8119 8.20573 37.666C8.20573 36.5202 8.60556 35.5393 9.40521 34.7233C10.2049 33.9073 11.1661 33.4994 12.2891 33.4994C13.412 33.4994 14.3733 33.9073 15.1729 34.7233C15.9726 35.5393 16.3724 36.5202 16.3724 37.666C16.3724 38.8119 15.9726 39.7928 15.1729 40.6087C14.3733 41.4247 13.412 41.8327 12.2891 41.8327ZM32.7057 41.8327C31.5828 41.8327 30.6215 41.4247 29.8219 40.6087C29.0222 39.7928 28.6224 38.8119 28.6224 37.666C28.6224 36.5202 29.0222 35.5393 29.8219 34.7233C30.6215 33.9073 31.5828 33.4994 32.7057 33.4994C33.8286 33.4994 34.7899 33.9073 35.5896 34.7233C36.3892 35.5393 36.7891 36.5202 36.7891 37.666C36.7891 38.8119 36.3892 39.7928 35.5896 40.6087C34.7899 41.4247 33.8286 41.8327 32.7057 41.8327ZM10.5536 8.49935L15.4536 18.916H29.7453L35.3599 8.49935H10.5536ZM8.61406 4.33268H38.7286C39.5113 4.33268 40.1068 4.68859 40.5151 5.40039C40.9234 6.1122 40.9405 6.83268 40.5661 7.56185L33.3182 20.8952C32.9439 21.5896 32.442 22.1278 31.8125 22.5098C31.183 22.8917 30.4939 23.0827 29.7453 23.0827H14.5349L12.2891 27.2494H36.7891V31.416H12.2891C10.7578 31.416 9.60087 30.7303 8.81823 29.3587C8.03559 27.9872 8.00156 26.6244 8.71615 25.2702L11.4724 20.166L4.1224 4.33268H0.0390625V0.166016H6.67448L8.61406 4.33268Z"/>
    </svg>`,
    "settings-n": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.9,12.66a1,1,0,0,1,0-1.32L21.18,9.9a1,1,0,0,0,.12-1.17l-2-3.46a1,1,0,0,0-1.07-.48l-1.88.38a1,1,0,0,1-1.15-.66l-.61-1.83A1,1,0,0,0,13.64,2h-4a1,1,0,0,0-1,.68L8.08,4.51a1,1,0,0,1-1.15.66L5,4.79A1,1,0,0,0,4,5.27L2,8.73A1,1,0,0,0,2.1,9.9l1.27,1.44a1,1,0,0,1,0,1.32L2.1,14.1A1,1,0,0,0,2,15.27l2,3.46a1,1,0,0,0,1.07.48l1.88-.38a1,1,0,0,1,1.15.66l.61,1.83a1,1,0,0,0,1,.68h4a1,1,0,0,0,.95-.68l.61-1.83a1,1,0,0,1,1.15-.66l1.88.38a1,1,0,0,0,1.07-.48l2-3.46a1,1,0,0,0-.12-1.17ZM18.41,14l.8.9-1.28,2.22-1.18-.24a3,3,0,0,0-3.45,2L12.92,20H10.36L10,18.86a3,3,0,0,0-3.45-2l-1.18.24L4.07,14.89l.8-.9a3,3,0,0,0,0-4l-.8-.9L5.35,6.89l1.18.24a3,3,0,0,0,3.45-2L10.36,4h2.56l.38,1.14a3,3,0,0,0,3.45,2l1.18-.24,1.28,2.22-.8.9A3,3,0,0,0,18.41,14ZM11.64,8a4,4,0,1,0,4,4A4,4,0,0,0,11.64,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,11.64,14Z"></path></svg>`,
    'quiz-n': `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
    <path d="M15.5 16.75C15.8542 16.75 16.1617 16.62 16.4225 16.36C16.6825 16.0992 16.8125 15.7917 16.8125 15.4375C16.8125 15.0833 16.6825 14.7758 16.4225 14.515C16.1617 14.255 15.8542 14.125 15.5 14.125C15.1458 14.125 14.8387 14.255 14.5787 14.515C14.3179 14.7758 14.1875 15.0833 14.1875 15.4375C14.1875 15.7917 14.3179 16.0992 14.5787 16.36C14.8387 16.62 15.1458 16.75 15.5 16.75ZM14.5625 12.75H16.4375C16.4375 12.1458 16.5 11.7029 16.625 11.4213C16.75 11.1404 17.0417 10.7708 17.5 10.3125C18.125 9.6875 18.5417 9.18208 18.75 8.79625C18.9583 8.41125 19.0625 7.95833 19.0625 7.4375C19.0625 6.5 18.7342 5.73458 18.0775 5.14125C17.4217 4.54708 16.5625 4.25 15.5 4.25C14.6458 4.25 13.9013 4.48958 13.2663 4.96875C12.6304 5.44792 12.1875 6.08333 11.9375 6.875L13.625 7.5625C13.8125 7.04167 14.0679 6.65083 14.3912 6.39C14.7137 6.13 15.0833 6 15.5 6C16 6 16.4062 6.14042 16.7188 6.42125C17.0312 6.70292 17.1875 7.08333 17.1875 7.5625C17.1875 7.85417 17.1042 8.13 16.9375 8.39C16.7708 8.65083 16.4792 8.97917 16.0625 9.375C15.375 9.97917 14.9533 10.4529 14.7975 10.7963C14.6408 11.1404 14.5625 11.7917 14.5625 12.75ZM8 20.5C7.3125 20.5 6.72417 20.2554 6.235 19.7663C5.745 19.2763 5.5 18.6875 5.5 18V3C5.5 2.3125 5.745 1.72375 6.235 1.23375C6.72417 0.744583 7.3125 0.5 8 0.5H23C23.6875 0.5 24.2763 0.744583 24.7663 1.23375C25.2554 1.72375 25.5 2.3125 25.5 3V18C25.5 18.6875 25.2554 19.2763 24.7663 19.7663C24.2763 20.2554 23.6875 20.5 23 20.5H8ZM8 18H23V3H8V18ZM3 25.5C2.3125 25.5 1.72417 25.2554 1.235 24.7663C0.745 24.2763 0.5 23.6875 0.5 23V5.5H3V23H20.5V25.5H3Z"/>
    </svg>`,
    'language': `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" role="img" aria-labelledby="languageIconTitle" stroke="#000000" stroke-width="1" stroke-linecap="square" stroke-linejoin="miter" fill="none" color="#000000"> <title id="languageIconTitle">Language</title> <circle cx="12" cy="12" r="10"/> <path stroke-linecap="round" d="M12,22 C14.6666667,19.5757576 16,16.2424242 16,12 C16,7.75757576 14.6666667,4.42424242 12,2 C9.33333333,4.42424242 8,7.75757576 8,12 C8,16.2424242 9.33333333,19.5757576 12,22 Z"/> <path stroke-linecap="round" d="M2.5 9L21.5 9M2.5 15L21.5 15"/> </svg>`,
    'heart': `<svg  class="svg_icn" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 107.39"><defs><style>.cls-1{fill:#ed1b24;fill-rule:evenodd;}</style></defs><title>red-heart</title><path class="cls-1" d="M60.83,17.18c8-8.35,13.62-15.57,26-17C110-2.46,131.27,21.26,119.57,44.61c-3.33,6.65-10.11,14.56-17.61,22.32-8.23,8.52-17.34,16.87-23.72,23.2l-17.4,17.26L46.46,93.55C29.16,76.89,1,55.92,0,29.94-.63,11.74,13.73.08,30.25.29c14.76.2,21,7.54,30.58,16.89Z"/></svg>`,
    'shopping-cart': `<svg class="svg_icn"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41 42">
    <path d="M12.2891 41.8327C11.1661 41.8327 10.2049 41.4247 9.40521 40.6087C8.60556 39.7928 8.20573 38.8119 8.20573 37.666C8.20573 36.5202 8.60556 35.5393 9.40521 34.7233C10.2049 33.9073 11.1661 33.4994 12.2891 33.4994C13.412 33.4994 14.3733 33.9073 15.1729 34.7233C15.9726 35.5393 16.3724 36.5202 16.3724 37.666C16.3724 38.8119 15.9726 39.7928 15.1729 40.6087C14.3733 41.4247 13.412 41.8327 12.2891 41.8327ZM32.7057 41.8327C31.5828 41.8327 30.6215 41.4247 29.8219 40.6087C29.0222 39.7928 28.6224 38.8119 28.6224 37.666C28.6224 36.5202 29.0222 35.5393 29.8219 34.7233C30.6215 33.9073 31.5828 33.4994 32.7057 33.4994C33.8286 33.4994 34.7899 33.9073 35.5896 34.7233C36.3892 35.5393 36.7891 36.5202 36.7891 37.666C36.7891 38.8119 36.3892 39.7928 35.5896 40.6087C34.7899 41.4247 33.8286 41.8327 32.7057 41.8327ZM10.5536 8.49935L15.4536 18.916H29.7453L35.3599 8.49935H10.5536ZM8.61406 4.33268H38.7286C39.5113 4.33268 40.1068 4.68859 40.5151 5.40039C40.9234 6.1122 40.9405 6.83268 40.5661 7.56185L33.3182 20.8952C32.9439 21.5896 32.442 22.1278 31.8125 22.5098C31.183 22.8917 30.4939 23.0827 29.7453 23.0827H14.5349L12.2891 27.2494H36.7891V31.416H12.2891C10.7578 31.416 9.60087 30.7303 8.81823 29.3587C8.03559 27.9872 8.00156 26.6244 8.71615 25.2702L11.4724 20.166L4.1224 4.33268H0.0390625V0.166016H6.67448L8.61406 4.33268Z"/>
    </svg>`,
    'info-icn': `<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_535_4392)"><path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 16V12" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M12 8H12.01" stroke="#344054" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></g><defs><clipPath id="clip0_535_4392"><rect width="24" height="24"/></clipPath></defs></svg>`,
    "exit": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"/></svg>`,
    "paypal": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.43705,7.10449a3.82273,3.82273,0,0,0-.57281-.5238,4.72529,4.72529,0,0,0-1.15766-3.73987C17.6226,1.61914,15.77494,1,13.2144,1H7.00053A1.89234,1.89234,0,0,0,5.13725,2.5918L2.5474,18.99805A1.53317,1.53317,0,0,0,4.063,20.7832H6.72709l-.082.52051A1.46684,1.46684,0,0,0,8.0933,23h3.23438a1.76121,1.76121,0,0,0,1.751-1.46973l.64063-4.03125.01074-.05468h.29883c4.03223,0,6.55078-1.99317,7.28516-5.7627A5.149,5.149,0,0,0,20.43705,7.10449ZM7.84233,13.7041l-.71448,4.53528-.08631.54382H4.606L7.09721,3H13.2144c1.93554,0,3.31738.4043,3.99218,1.16406a2.96675,2.96675,0,0,1,.60791,2.73334l-.01861.11224c-.01215.07648-.0232.15119-.0434.24622a5.84606,5.84606,0,0,1-2.00512,3.67053,6.67728,6.67728,0,0,1-4.21753,1.183H9.70658A1.87969,1.87969,0,0,0,7.84233,13.7041Zm11.50878-2.40527c-.55078,2.82812-2.24218,4.14551-5.32226,4.14551h-.4834a1.76109,1.76109,0,0,0-1.751,1.47265l-.64941,4.07422L8.71733,21l.47815-3.03387.61114-3.85285h1.7193c.1568,0,.29541-.02356.44812-.02893.35883-.01239.71661-.02618,1.05267-.06787.20526-.02557.39362-.07221.59034-.1087.27252-.05036.54522-.10016.80108-.17127.19037-.053.368-.12121.54907-.18561.23926-.0849.4748-.174.69757-.27868.168-.0791.32807-.16706.48658-.25727a6.77125,6.77125,0,0,0,.61236-.39172c.14228-.1026.28192-.20789.415-.321a6.56392,6.56392,0,0,0,.53693-.51892c.113-.12055.2287-.23755.33331-.36725a7.09,7.09,0,0,0,.48-.69263c.07648-.12219.16126-.23523.23163-.36383a8.33175,8.33175,0,0,0,.52075-1.15326c.00867-.02386.02106-.044.02954-.068.004-.01123.00989-.02057.01386-.03186A4.29855,4.29855,0,0,1,19.35111,11.29883Z"></path></svg>`,
    "creadit-cart": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M7,15h3a1,1,0,0,0,0-2H7a1,1,0,0,0,0,2ZM19,5H5A3,3,0,0,0,2,8v9a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V8A3,3,0,0,0,19,5Zm1,12a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20Zm0-8H4V8A1,1,0,0,1,5,7H19a1,1,0,0,1,1,1Z"></path></svg>`,
    "info": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,14a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,12,14Zm0-1.5a1,1,0,0,0,1-1v-3a1,1,0,0,0-2,0v3A1,1,0,0,0,12,12.5ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"/></svg>`,
    "content-text": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><title/><path d="M292.6,407.78l-120-320a22,22,0,0,0-41.2,0l-120,320a22,22,0,0,0,41.2,15.44L88.76,326.8a2,2,0,0,1,1.87-1.3H213.37a2,2,0,0,1,1.87,1.3l36.16,96.42a22,22,0,0,0,41.2-15.44Zm-185.84-129,43.37-115.65a2,2,0,0,1,3.74,0L197.24,278.8a2,2,0,0,1-1.87,2.7H108.63A2,2,0,0,1,106.76,278.8Z"/><path d="M400.77,169.5c-41.72-.3-79.08,23.87-95,61.4a22,22,0,0,0,40.5,17.2c8.88-20.89,29.77-34.44,53.32-34.6C431.91,213.28,458,240,458,272.35h0a1.5,1.5,0,0,1-1.45,1.5c-21.92.61-47.92,2.07-71.12,4.8C330.68,285.09,298,314.94,298,358.5c0,23.19,8.76,44,24.67,58.68C337.6,430.93,358,438.5,380,438.5c31,0,57.69-8,77.94-23.22,0,0,.06,0,.06,0h0a22,22,0,1,0,44,.19v-143C502,216.29,457,169.91,400.77,169.5ZM380,394.5c-17.53,0-38-9.43-38-36,0-10.67,3.83-18.14,12.43-24.23,8.37-5.93,21.2-10.16,36.14-11.92,21.12-2.49,44.82-3.86,65.14-4.47a2,2,0,0,1,2,2.1C455,370.1,429.46,394.5,380,394.5Z"/></svg>`,
    "list-accordion-old": `<svg class="svg_icn" xmlns='http://www.w3.org/2000/svg' viewbox='0 0 24 24'><path d='m13 6h-13v-4h13v4zm0 4h-13v4h13v-4zm0 8h-13v4h13v-4zm3-8l4 5.075 4-5.075h-8z'/></svg>`,
    "list-accordion": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 200 200"><title/><path d="M110.22,117.75h-80a10,10,0,0,0,0,20h80a10,10,0,0,0,0-20Z"/><path d="M177.22,125.75a9.67,9.67,0,0,0-14,0l-8,7.5V42.75a10,10,0,0,0-20,0v113.5a8.29,8.29,0,0,0,3,8,9.67,9.67,0,0,0,14,0l24.5-24.5a10.13,10.13,0,0,0,.5-14Z"/><path d="M110.22,37.75h-80a10,10,0,0,0,0,20h80a10,10,0,0,0,0-20Z"/><path d="M30.22,97.75h70a10,10,0,0,0,0-20h-70a10,10,0,0,0,0,20Z"/></svg>`,
    "list": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3.71,16.29a1,1,0,0,0-.33-.21,1,1,0,0,0-.76,0,1,1,0,0,0-.33.21,1,1,0,0,0-.21.33,1,1,0,0,0,.21,1.09,1.15,1.15,0,0,0,.33.21.94.94,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21,1,1,0,0,0,.21-1.09A1,1,0,0,0,3.71,16.29ZM7,8H21a1,1,0,0,0,0-2H7A1,1,0,0,0,7,8ZM3.71,11.29a1,1,0,0,0-1.09-.21,1.15,1.15,0,0,0-.33.21,1,1,0,0,0-.21.33.94.94,0,0,0,0,.76,1.15,1.15,0,0,0,.21.33,1.15,1.15,0,0,0,.33.21.94.94,0,0,0,.76,0,1.15,1.15,0,0,0,.33-.21,1.15,1.15,0,0,0,.21-.33.94.94,0,0,0,0-.76A1,1,0,0,0,3.71,11.29ZM21,11H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2ZM3.71,6.29a1,1,0,0,0-.33-.21,1,1,0,0,0-1.09.21,1.15,1.15,0,0,0-.21.33.94.94,0,0,0,0,.76,1.15,1.15,0,0,0,.21.33,1.15,1.15,0,0,0,.33.21,1,1,0,0,0,1.09-.21,1.15,1.15,0,0,0,.21-.33.94.94,0,0,0,0-.76A1.15,1.15,0,0,0,3.71,6.29ZM21,16H7a1,1,0,0,0,0,2H21a1,1,0,0,0,0-2Z"/></svg>`,
    "grid": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM8,20H4V4H8Zm12,0H10V13H20Zm0-9H10V4H20Z" /></svg>`,
    "columns": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM8,20H4V4H8Zm6,0H10V4h4Zm6,0H16V4h4Z"></path></svg>`,
    "container": `<svg class="svg_icn" style='transform: rotate(90deg)' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M21,2H3A1,1,0,0,0,2,3V21a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V3A1,1,0,0,0,21,2ZM8,20H4V4H8Zm6,0H10V4h4Zm6,0H16V4h4Z"></path></svg>`,
    "separator": `<svg class="svg_icn" xmlns='http://www.w3.org/2000/svg' viewbox='0 0 24 24'><path d='m12 3.202l3.839 4.798h-7.678l3.839-4.798zm0-3.202l-8 10h16l-8-10zm3.839 16l-3.839 4.798-3.839-4.798h7.678zm4.161-2h-16l8 10 8-10z'/></svg>`,
    "social-icons": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M18,14a4,4,0,0,0-3.08,1.48l-5.1-2.35a3.64,3.64,0,0,0,0-2.26l5.1-2.35A4,4,0,1,0,14,6a4.17,4.17,0,0,0,.07.71L8.79,9.14a4,4,0,1,0,0,5.72l5.28,2.43A4.17,4.17,0,0,0,14,18a4,4,0,1,0,4-4ZM18,4a2,2,0,1,1-2,2A2,2,0,0,1,18,4ZM6,14a2,2,0,1,1,2-2A2,2,0,0,1,6,14Zm12,6a2,2,0,1,1,2-2A2,2,0,0,1,18,20Z"></path></svg>`,
    "subscribe-form": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12,2a10,10,0,1,0,5,18.66,1,1,0,1,0-1-1.73A8,8,0,1,1,20,12v.75a1.75,1.75,0,0,1-3.5,0V8.5a1,1,0,0,0-1-1,1,1,0,0,0-1,.79A4.45,4.45,0,0,0,12,7.5,4.5,4.5,0,1,0,15.3,15,3.74,3.74,0,0,0,22,12.75V12A10,10,0,0,0,12,2Zm0,12.5A2.5,2.5,0,1,1,14.5,12,2.5,2.5,0,0,1,12,14.5Z"></path></svg>`,
    "express-checkout": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.5,15a3,3,0,0,0-1.9-2.78l1.87-7a1,1,0,0,0-.18-.87A1,1,0,0,0,20.5,4H6.8L6.47,2.74A1,1,0,0,0,5.5,2h-2V4H4.73l2.48,9.26a1,1,0,0,0,1,.74H18.5a1,1,0,0,1,0,2H5.5a1,1,0,0,0,0,2H6.68a3,3,0,1,0,5.64,0h2.36a3,3,0,1,0,5.82,1,2.94,2.94,0,0,0-.4-1.47A3,3,0,0,0,21.5,15Zm-3.91-3H9L7.34,6H19.2ZM9.5,20a1,1,0,1,1,1-1A1,1,0,0,1,9.5,20Zm8,0a1,1,0,1,1,1-1A1,1,0,0,1,17.5,20Z"></path></svg>`,
    "image": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M19,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a2.81,2.81,0,0,0,.49-.05l.3-.07.07,0h0l.05,0,.37-.14.13-.07c.1-.06.21-.11.31-.18a3.79,3.79,0,0,0,.38-.32l.07-.09a2.69,2.69,0,0,0,.27-.32l.09-.13a2.31,2.31,0,0,0,.18-.35,1,1,0,0,0,.07-.15c.05-.12.08-.25.12-.38l0-.15A2.6,2.6,0,0,0,22,19V5A3,3,0,0,0,19,2ZM5,20a1,1,0,0,1-1-1V14.69l3.29-3.3h0a1,1,0,0,1,1.42,0L17.31,20Zm15-1a1,1,0,0,1-.07.36,1,1,0,0,1-.08.14.94.94,0,0,1-.09.12l-5.35-5.35.88-.88a1,1,0,0,1,1.42,0h0L20,16.69Zm0-5.14L18.12,12a3.08,3.08,0,0,0-4.24,0l-.88.88L10.12,10a3.08,3.08,0,0,0-4.24,0L4,11.86V5A1,1,0,0,1,5,4H19a1,1,0,0,1,1,1Z"></path></svg>`,
    "video": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" fill="none"  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"/><polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"/></svg>`,
    "video-circal": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M16,10.27,11,7.38A2,2,0,0,0,8,9.11v5.78a2,2,0,0,0,1,1.73,2,2,0,0,0,2,0l5-2.89a2,2,0,0,0,0-3.46ZM15,12l-5,2.89V9.11L15,12ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>`,
    "more": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,7a2,2,0,1,0-2-2A2,2,0,0,0,12,7Zm0,10a2,2,0,1,0,2,2A2,2,0,0,0,12,17Zm0-7a2,2,0,1,0,2,2A2,2,0,0,0,12,10Z"/></svg>`,
    "image-list": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18,15V5a3,3,0,0,0-3-3H5A3,3,0,0,0,2,5V15a3,3,0,0,0,3,3H15A3,3,0,0,0,18,15ZM4,5A1,1,0,0,1,5,4H15a1,1,0,0,1,1,1V9.36L14.92,8.27a2.56,2.56,0,0,0-1.81-.75h0a2.58,2.58,0,0,0-1.81.75l-.91.91-.81-.81a2.93,2.93,0,0,0-4.11,0L4,9.85Zm.12,10.45A.94.94,0,0,1,4,15V12.67L6.88,9.79a.91.91,0,0,1,1.29,0L9,10.6Zm8.6-5.76a.52.52,0,0,1,.39-.17h0a.52.52,0,0,1,.39.17L16,12.18V15a1,1,0,0,1-1,1H6.4ZM21,6a1,1,0,0,0-1,1V17a3,3,0,0,1-3,3H7a1,1,0,0,0,0,2H17a5,5,0,0,0,5-5V7A1,1,0,0,0,21,6Z"></path></svg>`,
    "floating": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M5.344 19.442l-1.186 1.628c-2.305-1.995-3.842-4.85-4.107-8.07h2c.255 2.553 1.48 4.819 3.293 6.442zm16.605-6.442c-.256 2.56-1.487 4.831-3.308 6.455l1.183 1.631c2.315-1.997 3.858-4.858 4.125-8.086h-2zm-19.898-2c.256-2.561 1.487-4.832 3.309-6.456l-1.183-1.631c-2.317 1.996-3.86 4.858-4.126 8.087h2zm4.927-7.633c1.477-.864 3.19-1.367 5.022-1.367 1.839 0 3.558.507 5.039 1.377l1.183-1.624c-1.817-1.105-3.941-1.753-6.222-1.753-2.272 0-4.39.644-6.201 1.741l1.179 1.626zm12.863-.438l-1.186 1.628c1.813 1.624 3.039 3.889 3.294 6.443h2c-.265-3.221-1.802-6.076-4.108-8.071zm-2.817 17.703c-1.478.864-3.192 1.368-5.024 1.368-1.84 0-3.56-.508-5.042-1.378l-1.183 1.624c1.818 1.106 3.943 1.754 6.225 1.754 2.273 0 4.392-.644 6.203-1.742l-1.179-1.626z"/></svg>`,
    "button": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg"  fill="none"  stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none" stroke="none"/><path d="M8 13v-8.5a1.5 1.5 0 0 1 3 0v7.5"/><path d="M11 11.5v-2a1.5 1.5 0 0 1 3 0v2.5"/><path d="M14 10.5a1.5 1.5 0 0 1 3 0v1.5"/><path d="M17 11.5a1.5 1.5 0 0 1 3 0v4.5a6 6 0 0 1 -6 6h-2h.208a6 6 0 0 1 -5.012 -2.7l-.196 -.3c-.312 -.479 -1.407 -2.388 -3.286 -5.728a1.5 1.5 0 0 1 .536 -2.022a1.867 1.867 0 0 1 2.28 .28l1.47 1.47"/><path d="M5 3l-1 -1"/><path d="M4 7h-1"/><path d="M14 3l1 -1"/><path d="M15 6h1"/></svg>`,
    "tag": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M19.7501 2C20.9927 2 22.0001 3.00736 22.0001 4.25V9.71196C22.0001 10.5738 21.6578 11.4003 21.0484 12.0098L12.5474 20.5129C11.2779 21.7798 9.2222 21.7807 7.95104 20.5143L3.48934 16.0592C2.21887 14.7913 2.21724 12.7334 3.48556 11.4632L11.9852 2.95334C12.5948 2.34297 13.4221 2 14.2847 2H19.7501ZM17 5.50218C16.1716 5.50218 15.5001 6.17374 15.5001 7.00216C15.5001 7.83057 16.1716 8.50213 17 8.50213C17.8284 8.50213 18.5 7.83057 18.5 7.00216C18.5 6.17374 17.8284 5.50218 17 5.50218Z"/></svg>`,
    "tags": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M12.7507 2C11.9154 2 11.1121 2.32164 10.5076 2.89817L3.69613 9.39468C2.78029 10.2682 2.76479 11.7249 3.66184 12.6177L9.91845 18.8445C10.7927 19.7146 12.2045 19.7186 13.0837 18.8535L20.0291 12.0192C20.65 11.4083 20.9996 10.5737 20.9996 9.70264V4.75C20.9996 3.23122 19.7684 2 18.2496 2H12.7507ZM16.25 7.75C15.5596 7.75 15 7.19036 15 6.5C15 5.80964 15.5596 5.25 16.25 5.25C16.9404 5.25 17.5 5.80964 17.5 6.5C17.5 7.19036 16.9404 7.75 16.25 7.75ZM19.2558 14.1834L20.7309 12.732C20.776 12.6876 20.82 12.6423 20.8628 12.5962C21.1728 13.5543 20.9449 14.6514 20.1717 15.4093L14.8384 20.6377C12.9851 22.4544 10.0168 22.4475 8.17213 20.6221L3.81573 16.3113C3.05086 15.5545 2.82374 14.4607 3.13495 13.5037L4.76374 15.1248C4.79639 15.1666 4.83207 15.2068 4.87078 15.2451L7.28204 17.6311L9.21341 19.5532C10.2687 20.6035 11.8661 20.7802 13.1037 20.0807C13.3482 19.9434 13.5787 19.772 13.7883 19.5665L19.1217 14.3381C19.1713 14.2894 19.216 14.2377 19.2558 14.1834Z"/></svg>`,
    "right": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M17.92,11.62a1,1,0,0,0-.21-.33l-5-5a1,1,0,0,0-1.42,1.42L14.59,11H7a1,1,0,0,0,0,2h7.59l-3.3,3.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l5-5a1,1,0,0,0,.21-.33A1,1,0,0,0,17.92,11.62Z"></path></svg>`,
    "left": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M15.54,11.29,9.88,5.64a1,1,0,0,0-1.42,0,1,1,0,0,0,0,1.41l4.95,5L8.46,17a1,1,0,0,0,0,1.41,1,1,0,0,0,.71.3,1,1,0,0,0,.71-.3l5.66-5.65A1,1,0,0,0,15.54,11.29Z" /></svg>`,
    "print": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7,10a1,1,0,1,0,1,1A1,1,0,0,0,7,10ZM19,6H18V3a1,1,0,0,0-1-1H7A1,1,0,0,0,6,3V6H5A3,3,0,0,0,2,9v6a3,3,0,0,0,3,3H6v3a1,1,0,0,0,1,1H17a1,1,0,0,0,1-1V18h1a3,3,0,0,0,3-3V9A3,3,0,0,0,19,6ZM8,4h8V6H8Zm8,16H8V16h8Zm4-5a1,1,0,0,1-1,1H18V15a1,1,0,0,0-1-1H7a1,1,0,0,0-1,1v1H5a1,1,0,0,1-1-1V9A1,1,0,0,1,5,8H19a1,1,0,0,1,1,1Z"></path></svg>`,
    "eye": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21.92,11.6C19.9,6.91,16.1,4,12,4S4.1,6.91,2.08,11.6a1,1,0,0,0,0,.8C4.1,17.09,7.9,20,12,20s7.9-2.91,9.92-7.6A1,1,0,0,0,21.92,11.6ZM12,18c-3.17,0-6.17-2.29-7.9-6C5.83,8.29,8.83,6,12,6s6.17,2.29,7.9,6C18.17,15.71,15.17,18,12,18ZM12,8a4,4,0,1,0,4,4A4,4,0,0,0,12,8Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,14Z"></path></svg>`,
    "close": `<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 512 512;" version="1.1" viewBox="0 0 512 512" xml:space="preserve"><g><path d="M256,33C132.3,33,32,133.3,32,257c0,123.7,100.3,224,224,224c123.7,0,224-100.3,224-224C480,133.3,379.7,33,256,33z    M364.3,332.5c1.5,1.5,2.3,3.5,2.3,5.6c0,2.1-0.8,4.2-2.3,5.6l-21.6,21.7c-1.6,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3L256,289.8   l-75.4,75.7c-1.5,1.6-3.6,2.3-5.6,2.3c-2,0-4.1-0.8-5.6-2.3l-21.6-21.7c-1.5-1.5-2.3-3.5-2.3-5.6c0-2.1,0.8-4.2,2.3-5.6l75.7-76   l-75.9-75c-3.1-3.1-3.1-8.2,0-11.3l21.6-21.7c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l75.7,74.7l75.7-74.7   c1.5-1.5,3.5-2.3,5.6-2.3c2.1,0,4.1,0.8,5.6,2.3l21.6,21.7c3.1,3.1,3.1,8.2,0,11.3l-75.9,75L364.3,332.5z"/></g></svg>`,
    "circal-check": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"/></svg>`,
    "folder-check": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.29,10.79,11,14.09l-1.29-1.3a1,1,0,0,0-1.42,1.42l2,2a1,1,0,0,0,1.42,0l4-4a1,1,0,0,0-1.42-1.42ZM19,5.5H12.72l-.32-1a3,3,0,0,0-2.84-2H5a3,3,0,0,0-3,3v13a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V8.5A3,3,0,0,0,19,5.5Zm1,13a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5.5a1,1,0,0,1,1-1H9.56a1,1,0,0,1,.95.68l.54,1.64A1,1,0,0,0,12,7.5h7a1,1,0,0,1,1,1Z"/></svg>`,
    "dot": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 16 16" xml:space="preserve"><circle cx="8" cy="8" r="8"/></svg>`,
    "king": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><g data-name="30. Crown" id="_30._Crown"><path d="M24,9a3,3,0,0,0-6,0,2.964,2.964,0,0,0,.061.6l-1.65.988L14.154,6.08a3,3,0,1,0-4.308,0L7.589,10.588,5.939,9.6A2.964,2.964,0,0,0,6,9a3,3,0,1,0-3.684,2.914l.6,5.409A2,2,0,0,0,2,19v2a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V19a2,2,0,0,0-.918-1.677l.6-5.409A3,3,0,0,0,24,9ZM21,8a1,1,0,1,1-1,1A1,1,0,0,1,21,8ZM12,3a1,1,0,1,1-1,1A1,1,0,0,1,12,3ZM8,13a1.023,1.023,0,0,0,.279-.04,1,1,0,0,0,.616-.512l2.747-5.484A2.993,2.993,0,0,0,12,7a2.993,2.993,0,0,0,.358-.036l2.747,5.484a1,1,0,0,0,.616.512A1.017,1.017,0,0,0,16,13a1,1,0,0,0,.514-.142l2.591-1.551a3.032,3.032,0,0,0,.591.382L19.105,17H4.895L4.3,11.689a3.032,3.032,0,0,0,.591-.382l2.591,1.551A1,1,0,0,0,8,13ZM3,8A1,1,0,1,1,2,9,1,1,0,0,1,3,8ZM4,21V19H20l0,2Z"/></g></svg>`,
    "star": `<svg class="svg_icn" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g /><g ><path d="M12.9,2.6l2.3,5c0.1,0.3,0.4,0.5,0.7,0.6l5.2,0.8C22,9,22.3,10,21.7,10.6l-3.8,3.9c-0.2,0.2-0.3,0.6-0.3,0.9   l0.9,5.4c0.1,0.8-0.7,1.5-1.4,1.1l-4.7-2.6c-0.3-0.2-0.6-0.2-0.9,0l-4.7,2.6c-0.7,0.4-1.6-0.2-1.4-1.1l0.9-5.4   c0.1-0.3-0.1-0.7-0.3-0.9l-3.8-3.9C1.7,10,2,9,2.8,8.9l5.2-0.8c0.3,0,0.6-0.3,0.7-0.6l2.3-5C11.5,1.8,12.5,1.8,12.9,2.6z" /></g></svg>`,
    "save": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.71,9.29l-6-6a1,1,0,0,0-.32-.21A1.09,1.09,0,0,0,14,3H6A3,3,0,0,0,3,6V18a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V10A1,1,0,0,0,20.71,9.29ZM9,5h4V7H9Zm6,14H9V16a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1Zm4-1a1,1,0,0,1-1,1H17V16a3,3,0,0,0-3-3H10a3,3,0,0,0-3,3v3H6a1,1,0,0,1-1-1V6A1,1,0,0,1,6,5H7V8A1,1,0,0,0,8,9h6a1,1,0,0,0,1-1V6.41l4,4Z"></path></svg>`,
    "cancel": `<svg class="svg_icn"  version="1.1" viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9  c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z"/></svg>`,
    "arraw-bottom": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>`,
    "refresh": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19.91,15.51H15.38a1,1,0,0,0,0,2h2.4A8,8,0,0,1,4,12a1,1,0,0,0-2,0,10,10,0,0,0,16.88,7.23V21a1,1,0,0,0,2,0V16.5A1,1,0,0,0,19.91,15.51ZM12,2A10,10,0,0,0,5.12,4.77V3a1,1,0,0,0-2,0V7.5a1,1,0,0,0,1,1h4.5a1,1,0,0,0,0-2H6.22A8,8,0,0,1,20,12a1,1,0,0,0,2,0A10,10,0,0,0,12,2Z"/></svg>`,
    "edit": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ><path d="M22,7.24a1,1,0,0,0-.29-.71L17.47,2.29A1,1,0,0,0,16.76,2a1,1,0,0,0-.71.29L13.22,5.12h0L2.29,16.05a1,1,0,0,0-.29.71V21a1,1,0,0,0,1,1H7.24A1,1,0,0,0,8,21.71L18.87,10.78h0L21.71,8a1.19,1.19,0,0,0,.22-.33,1,1,0,0,0,0-.24.7.7,0,0,0,0-.14ZM6.83,20H4V17.17l9.93-9.93,2.83,2.83ZM18.17,8.66,15.34,5.83l1.42-1.41,2.82,2.82Z"></path></svg>`,
    "delete": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10,18a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,10,18ZM20,6H16V5a3,3,0,0,0-3-3H11A3,3,0,0,0,8,5V6H4A1,1,0,0,0,4,8H5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V8h1a1,1,0,0,0,0-2ZM10,5a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1V6H10Zm7,14a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V8H17Zm-3-1a1,1,0,0,0,1-1V11a1,1,0,0,0-2,0v6A1,1,0,0,0,14,18Z"></path></svg>`,
    "duplicate": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M21,8.94a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,14.05,2H10A3,3,0,0,0,7,5V6H6A3,3,0,0,0,3,9V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V18h1a3,3,0,0,0,3-3V9S21,9,21,8.94ZM15,5.41,17.59,8H16a1,1,0,0,1-1-1ZM15,19a1,1,0,0,1-1,1H6a1,1,0,0,1-1-1V9A1,1,0,0,1,6,8H7v7a3,3,0,0,0,3,3h5Zm4-4a1,1,0,0,1-1,1H10a1,1,0,0,1-1-1V5a1,1,0,0,1,1-1h3V7a3,3,0,0,0,3,3h3Z"></path></svg>`,
    "check": `<?xml class="svg_icn" version="1.0" ?><svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18.71,7.21a1,1,0,0,0-1.42,0L9.84,14.67,6.71,11.53A1,1,0,1,0,5.29,13l3.84,3.84a1,1,0,0,0,1.42,0l8.16-8.16A1,1,0,0,0,18.71,7.21Z"/></svg>`,
    "back": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"/></svg>`,
    "back-new": `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><mask id="mask0_2307_17938" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24"><rect width="24" height="24" fill="#D9D9D9"/></mask><g mask="url(#mask0_2307_17938)"><path d="M12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825L13.425 18.6L12 20Z" fill="#252E56"/></g></svg>`,
    "align-left": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M30 30h-24v4h24v-4zm0-16h-24v4h24v-4zm-24 12h36v-4h-36v4zm0 16h36v-4h-36v4zm0-36v4h36v-4h-36z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>`,

    "align-right": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><path d="M6 42h36v-4h-36v4zm12-8h24v-4h-24v4zm-12-8h36v-4h-36v4zm12-8h24v-4h-24v4zm-12-12v4h36v-4h-36z"/><path d="M0 0h48v48h-48z" fill="none"/></svg>`,
    "closed-lock": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M18 10v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10-4c0-2.206 1.794-4 4-4s4 1.794 4 4v4h-8v-4zm11 16h-14v-10h14v10z"/></svg>`,
    "opened-lock": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M8 10v-4c0-2.206 1.795-4 4-4s4 1.794 4 4v1h2v-1c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-13zm11 12h-14v-10h14v10z"/></svg>`,
    "color-picker": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M18.717 8.831c-.734.824-.665 2.087.158 2.825l-1.333 1.491-7.455-6.667 1.334-1.49c.822.736 2.087.666 2.822-.159l3.503-3.831c.593-.663 1.414-1 2.238-1 1.666 0 3.016 1.358 3.016 2.996 0 .723-.271 1.435-.779 2.005l-3.504 3.83zm-8.217 6.169h-2.691l3.928-4.362-1.491-1.333-7.9 8.794c-1.277 1.423-.171 2.261-1.149 4.052-.135.244-.197.48-.197.698 0 .661.54 1.151 1.141 1.151.241 0 .492-.079.724-.256 1.733-1.332 2.644-.184 3.954-1.647l7.901-8.792-1.491-1.333-2.729 3.028z"/></svg>`,
    "plus": `<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"  version="1.1" viewBox="0 0 32 32" xml:space="preserve"><path d="M28,14H18V4c0-1.104-0.896-2-2-2s-2,0.896-2,2v10H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h10v10c0,1.104,0.896,2,2,2  s2-0.896,2-2V18h10c1.104,0,2-0.896,2-2S29.104,14,28,14z"/></svg>`,
    "minus": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 10h24v4h-24z"/></svg>`,
    "phone": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M12.71,16.29l-.15-.12a.76.76,0,0,0-.18-.09L12.2,16a1,1,0,0,0-.91.27,1.15,1.15,0,0,0-.21.33,1,1,0,0,0,1.3,1.31,1.46,1.46,0,0,0,.33-.22,1,1,0,0,0,.21-1.09A1,1,0,0,0,12.71,16.29ZM16,2H8A3,3,0,0,0,5,5V19a3,3,0,0,0,3,3h8a3,3,0,0,0,3-3V5A3,3,0,0,0,16,2Zm1,17a1,1,0,0,1-1,1H8a1,1,0,0,1-1-1V5A1,1,0,0,1,8,4h8a1,1,0,0,1,1,1Z"></path></svg>`,
    "laptop": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M21,14H20V7a3,3,0,0,0-3-3H7A3,3,0,0,0,4,7v7H3a1,1,0,0,0-1,1v2a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A1,1,0,0,0,21,14ZM6,7A1,1,0,0,1,7,6H17a1,1,0,0,1,1,1v7H6ZM20,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V16H20Z"></path></svg>`,
    "link": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10,17.55,8.23,19.27a2.47,2.47,0,0,1-3.5-3.5l4.54-4.55a2.46,2.46,0,0,1,3.39-.09l.12.1a1,1,0,0,0,1.4-1.43A2.75,2.75,0,0,0,14,9.59a4.46,4.46,0,0,0-6.09.22L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33L11.37,19A1,1,0,0,0,10,17.55ZM20.69,3.31a4.49,4.49,0,0,0-6.33,0L12.63,5A1,1,0,0,0,14,6.45l1.73-1.72a2.47,2.47,0,0,1,3.5,3.5l-4.54,4.55a2.46,2.46,0,0,1-3.39.09l-.12-.1a1,1,0,0,0-1.4,1.43,2.75,2.75,0,0,0,.23.21,4.47,4.47,0,0,0,6.09-.22l4.55-4.55A4.49,4.49,0,0,0,20.69,3.31Z"></path></svg>`,
    "success": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><path d="M26,52A26,26,0,1,1,52,26,26,26,0,0,1,26,52ZM26,4A22,22,0,1,0,48,26,22,22,0,0,0,26,4Z"/><path d="M23,37.6A2,2,0,0,1,21.59,37l-9.2-9.19A2,2,0,0,1,15.22,25l9.19,9.19A2,2,0,0,1,23,37.6Z"/><path d="M23,37.6a2,2,0,0,1-1.41-3.42L36.78,19a2,2,0,0,1,2.83,2.83L24.41,37A2,2,0,0,1,23,37.6Z"/></svg>`,
    "no-image": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M21.71,20.29l-.93-.93-.09-.1-.06-.07-.5-.5L20,18.62l-5.18-5.2-.09-.08-3.2-3.21L11.43,10,3.71,2.29A1,1,0,0,0,2.29,3.71l1,1A3,3,0,0,0,3,6V18a3,3,0,0,0,3,3H18a2.9,2.9,0,0,0,1.27-.31l0,0h0s0,0,.05,0l.95,1a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29ZM5,6.41,8.24,9.65a2.84,2.84,0,0,0-.67.48L5,12.71ZM6,19a1,1,0,0,1-1-1V15.54l4-4a.81.81,0,0,1,1.1,0L17.59,19ZM9.66,5H18a1,1,0,0,1,1,1v5.94l0,0a1,1,0,1,0-1.42,1.42l1.74,1.74a1,1,0,0,0,1.42,0,1,1,0,0,0,.29-.72h0V6a3,3,0,0,0-3-3H9.66a1,1,0,0,0,0,2Z"/></svg>`,
    "templates": `<svg class="svg_icn" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7,16a1,1,0,1,0,1,1A1,1,0,0,0,7,16Zm12.06-4,1.23-1.23a3,3,0,0,0,0-4.24L17.46,3.71a3,3,0,0,0-4.24,0L12,4.94A3,3,0,0,0,9,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V15A3,3,0,0,0,19.06,12ZM10,19a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4H9a1,1,0,0,1,1,1ZM12,7.76l2.64-2.64a1,1,0,0,1,1.41,0L18.88,8a1,1,0,0,1,0,1.41L16,12.29h0L12,16.24ZM20,19a1,1,0,0,1-1,1H11.82a3.12,3.12,0,0,0,.17-.92L17.07,14H19a1,1,0,0,1,1,1Z"></path></svg>`,
    "download": `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14.29,17.29,13,18.59V13a1,1,0,0,0-2,0v5.59l-1.29-1.3a1,1,0,0,0-1.42,1.42l3,3a1,1,0,0,0,.33.21.94.94,0,0,0,.76,0,1,1,0,0,0,.33-.21l3-3a1,1,0,0,0-1.42-1.42ZM18.42,6.22A7,7,0,0,0,5.06,8.11,4,4,0,0,0,6,16a1,1,0,0,0,0-2,2,2,0,0,1,0-4A1,1,0,0,0,7,9a5,5,0,0,1,9.73-1.61,1,1,0,0,0,.78.67,3,3,0,0,1,.24,5.84,1,1,0,1,0,.5,1.94,5,5,0,0,0,.17-9.62Z" /></svg>`,
    "calendar": `<svg class="svg_icn" data-name="Layer 1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,19a1,1,0,1,0-1-1A1,1,0,0,0,12,19Zm5,0a1,1,0,1,0-1-1A1,1,0,0,0,17,19Zm0-4a1,1,0,1,0-1-1A1,1,0,0,0,17,15Zm-5,0a1,1,0,1,0-1-1A1,1,0,0,0,12,15ZM19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A3,3,0,0,0,2,6V20a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V6A3,3,0,0,0,19,3Zm1,17a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V11H20ZM20,9H4V6A1,1,0,0,1,5,5H6V6A1,1,0,0,0,8,6V5h8V6a1,1,0,0,0,2,0V5h1a1,1,0,0,1,1,1ZM7,15a1,1,0,1,0-1-1A1,1,0,0,0,7,15Zm0,4a1,1,0,1,0-1-1A1,1,0,0,0,7,19Z" /></svg>`,
    'reservation': `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M9,10a1,1,0,0,0-1,1v2a1,1,0,0,0,2,0V11A1,1,0,0,0,9,10Zm12,1a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1H3A1,1,0,0,0,2,6v4a1,1,0,0,0,1,1,1,1,0,0,1,0,2,1,1,0,0,0-1,1v4a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V14a1,1,0,0,0-1-1,1,1,0,0,1,0-2ZM20,9.18a3,3,0,0,0,0,5.64V17H10a1,1,0,0,0-2,0H4V14.82A3,3,0,0,0,4,9.18V7H8a1,1,0,0,0,2,0H20Z"/></svg>`,
    'add-reservation': `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M14,9.45H13v-1a1,1,0,0,0-2,0v1H10a1,1,0,0,0,0,2h1v1a1,1,0,0,0,2,0v-1h1a1,1,0,0,0,0-2Zm6.46.18A8.5,8.5,0,1,0,6,16.46l5.3,5.31a1,1,0,0,0,1.42,0L18,16.46A8.46,8.46,0,0,0,20.46,9.63ZM16.6,15.05,12,19.65l-4.6-4.6A6.49,6.49,0,0,1,5.53,9.83,6.57,6.57,0,0,1,8.42,5a6.47,6.47,0,0,1,7.16,0,6.57,6.57,0,0,1,2.89,4.81A6.49,6.49,0,0,1,16.6,15.05Z"/></svg>`,
    'view-reservation': `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,20H5a1,1,0,0,1-1-1V5A1,1,0,0,1,5,4h5V7a3,3,0,0,0,3,3h3v1a1,1,0,0,0,2,0V9s0,0,0-.06a1.31,1.31,0,0,0-.06-.27l0-.09a1.07,1.07,0,0,0-.19-.28h0l-6-6h0a1.07,1.07,0,0,0-.28-.19.32.32,0,0,0-.09,0A.88.88,0,0,0,11.05,2H5A3,3,0,0,0,2,5V19a3,3,0,0,0,3,3h7a1,1,0,0,0,0-2ZM12,5.41,14.59,8H13a1,1,0,0,1-1-1ZM7,8a1,1,0,0,0,0,2H8A1,1,0,0,0,8,8ZM21.71,20.29l-1.17-1.16A3.44,3.44,0,0,0,20,15h0A3.49,3.49,0,0,0,14,17.49a3.46,3.46,0,0,0,5.13,3.05l1.16,1.17a1,1,0,0,0,1.42,0A1,1,0,0,0,21.71,20.29Zm-3.17-1.75a1.54,1.54,0,0,1-2.11,0A1.5,1.5,0,0,1,16,17.49a1.46,1.46,0,0,1,.44-1.06,1.48,1.48,0,0,1,1-.43A1.47,1.47,0,0,1,19,17.49,1.5,1.5,0,0,1,18.54,18.54ZM13,12H7a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Zm-2,6a1,1,0,0,0,0-2H7a1,1,0,0,0,0,2Z" /></svg>`,
    'location': `<svg class="svg_icn" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M18,4.48a8.45,8.45,0,0,0-12,12l5.27,5.28a1,1,0,0,0,1.42,0L18,16.43A8.45,8.45,0,0,0,18,4.48ZM16.57,15,12,19.59,7.43,15a6.46,6.46,0,1,1,9.14,0ZM9,7.41a4.32,4.32,0,0,0,0,6.1,4.31,4.31,0,0,0,7.36-3,4.24,4.24,0,0,0-1.26-3.05A4.3,4.3,0,0,0,9,7.41Zm4.69,4.68a2.33,2.33,0,1,1,.67-1.63A2.33,2.33,0,0,1,13.64,12.09Z"/></svg>`,
}