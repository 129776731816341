<template>
  <transition name="fade">
    <RouterView />
  </transition>
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>

</style>
