<template>
    <div class="w-full min-h-[60vh] flex justify-center items-center">
        <div class="flex min-h-[60vh] w-full items-center justify-center">
            <div
                class="flex h-24 w-24 items-center justify-center rounded-full bg-gradient-to-tr from-gray-500 to-[#dab37d] animate-spin">
                <div class="h-20 w-20 rounded-full bg-white"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'loading-spinner'
}
</script>